import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import TextImageHero from '@components/common/TextImageHero'
import { StaticImage } from 'gatsby-plugin-image'

const AboutUsOurMissionSection = ({ intl }) => {
  return (
    <SectionWrapper>
      <TextImageHero
        section
        reverse
        textAlignSm={'center'}
        title={intl.formatMessage({ id: 'aboutUs.ourMission.title' })}
        description={[
          intl.formatMessage({ id: 'aboutUs.ourMission.description.text01' }),
          intl.formatMessage({ id: 'aboutUs.ourMission.description.text02' }),
        ]}
        image={<StaticImage alt="hero" src="../../assets/images/aboutUs/ourMission.png" />}
      />
    </SectionWrapper>
  )
}

export default injectIntl(AboutUsOurMissionSection)
