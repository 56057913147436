import React from 'react'
import styled from 'styled-components'
import Modal from '@components/ui/modal/Modal'
import { color } from '@configs/utilities'

const WriteForUsModal = ({ open, handleClose }) => {
  return (
    <>
      <Modal open={open} handleClose={handleClose}>
        <Wrapper>
          <Header>Not accepting guest post submissions</Header>
          <Text>
            Thank you for expressing interest in submitting a guest post to our website. Unfortunately, we are not
            accepting guest posts at the moment.
          </Text>
        </Wrapper>
      </Modal>
    </>
  )
}

export default WriteForUsModal

const Wrapper = styled.div`
  max-width: 350px;
  color: ${color.blueDark};
  text-align: center;
`

const Header = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
`

const Text = styled.div`
  font-size: 16px;
`
