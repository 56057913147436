import React, { useState } from 'react'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import SectionDivider from '@components/common/SectionDivider'
import { color, breakpoint } from '@configs/utilities'
import styled from 'styled-components'
import ButtonLink from '@components/ui/buttons/ButtonLink'
import WriteForUsModal from '@components/pages/writeForUs/WriteForUsModal'
import getGlobalDocument from '@configs/getGlobalDocument'
import { OpenModalContext } from '@components/layouts/default/Layout'
import modalContactTypes from '@configs/modalContactTypes'

const ContactUsSection = ({ title, description, buttonText, writeForUsModal = false }) => {
  const [open, setOpen] = useState(false)

  function openWriteForUsModal(e) {
    e.preventDefault()
    setOpen(true)
    let documentStyle = getGlobalDocument().getElementsByTagName('html')[0]
    documentStyle.style.overflow = 'hidden'
  }

  function openContactModal(e, value) {
    e.preventDefault()
    value.openContact(modalContactTypes.CONTACT)
  }

  const handleClose = () => {
    setOpen(false)
    let documentStyle = getGlobalDocument().getElementsByTagName('html')[0]
    documentStyle.style.overflow = 'auto'
  }

  return (
    <SectionWrapper showBackground sectionBackground="#E3E9F5">
      <OpenModalContext.Consumer>
        {(value) => {
          return (
            <div>
              <WriteForUsModal open={open} handleClose={handleClose} />
              <SectionDivider hidePaddingBottom />
              <Wrapper>
                <p className="title">{title}</p>
                <p className="description">{description}</p>
                <ButtonLink
                  buttonBackground={color.blue}
                  buttonColor={color.white}
                  buttonText={buttonText}
                  onClick={(e) => (writeForUsModal ? openWriteForUsModal(e) : openContactModal(e, value))}
                />
              </Wrapper>
              <SectionDivider hidePaddingBottom />
            </div>
          )
        }}
      </OpenModalContext.Consumer>
    </SectionWrapper>
  )
}

export default ContactUsSection

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 624px;
  margin: 0 auto;
  row-gap: 40px;

  .title {
    font-size: 20px;
    font-weight: 700;

    @media screen and (min-width: ${breakpoint.md}) {
      font-size: 24px;
    }

    @media screen and (min-width: ${breakpoint.xl}) {
      font-size: 36px;
    }
  }

  .description {
    font-size: 16px;
    font-weight: 300;
    text-align: center;

    @media screen and (min-width: ${breakpoint.md}) {
      font-size: 18px;
    }
  }
`
