import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import SectionHeader from '@components/ui/typography/SectionHeader'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { breakpoint } from '@configs/utilities'
import BenefitsCard from '@components/pages/home/benefitsCard/BenefitsCard'

const AboutUsOurCoreValues = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      allCoreValuesCardsJson {
        edges {
          node {
            headImage {
              childImageSharp {
                gatsbyImageData(width: 116, placeholder: BLURRED, formats: [AUTO, WEBP], breakpoints: [320, 768, 1380])
              }
            }
            head
            text
          }
        }
      }
    }
  `)

  return (
    <SectionWrapper>
      <SectionHeader
        title={intl.formatMessage({ id: 'aboutUs.coreValues.title' })}
        description={intl.formatMessage({ id: 'aboutUs.coreValues.description' })}
      />
      <CardsWrapper>
        {data.allCoreValuesCardsJson.edges.map((item, index) => {
          return <BenefitsCard key={index} item={item} intl={intl} />
        })}
      </CardsWrapper>
    </SectionWrapper>
  )
}

export default injectIntl(AboutUsOurCoreValues)

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 50px;

  @media screen and (min-width: ${breakpoint.sm}) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media screen and (min-width: ${breakpoint.md}) {
    justify-content: space-between;
  }
`
