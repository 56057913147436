import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import { color } from '@configs/utilities'
import TextImageHero from '@components/common/TextImageHero'
import { StaticImage } from 'gatsby-plugin-image'

const AboutUsHeroSection = ({ intl }) => {
  return (
    <SectionWrapper showBackground sectionBackground={color.blueLight}>
      <TextImageHero
        title={intl.formatMessage({ id: 'aboutUs.hero.title' })}
        description={[
          intl.formatMessage({ id: 'aboutUs.hero.description.text01' }),
          intl.formatMessage({ id: 'aboutUs.hero.description.text02' }),
        ]}
        image={<StaticImage alt="hero" src="../../assets/images/aboutUs/hero.png" />}
      />
    </SectionWrapper>
  )
}

export default injectIntl(AboutUsHeroSection)
