import React from 'react'
import { breakpoint } from '@configs/utilities'
import styled from 'styled-components'

const SectionDivider = ({ hidePaddingBottom = false }) => {
  return <Wrapper hidePaddingBottom={hidePaddingBottom} />
}

export default SectionDivider

const Wrapper = styled.div`
  padding: 30px 0 ${(props) => (props.hidePaddingBottom ? 0 : '30px')};

  @media screen and (min-width: ${breakpoint.md}) {
    padding: 40px 0 ${(props) => (props.hidePaddingBottom ? 0 : '40px')};
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    padding: 50px 0 ${(props) => (props.hidePaddingBottom ? 0 : '50px')};
  }
`
