import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import Layout from '@components/layouts/default/Layout'
import SectionDivider from '@components/common/SectionDivider'
import AboutUsHeroSection from '@sections/about-us/AboutUsHeroSection'
import AboutUsOurCoreValues from '@sections/about-us/AboutUsOurCoreValues'
import TestimonialSection from '@sections/common/TestimonialSection'
import AboutUsOurMissionSection from '@sections/about-us/AboutUsOurMissionSection'
import ContactUsSection from '@sections/common/ContactUsSection'

const AboutUs = ({ intl }) => {
  return (
    <Layout pageName="about-us">
      <AboutUsHeroSection />
      <AboutUsOurCoreValues />
      <SectionDivider hidePaddingBottom />
      <TestimonialSection
        text={intl.formatMessage({ id: 'aboutUs.testimonial.text' })}
        signature={intl.formatMessage({ id: 'aboutUs.testimonial.signature' })}
      />
      <AboutUsOurMissionSection />
      <SectionDivider hidePaddingBottom />
      <ContactUsSection
        title={intl.formatMessage({ id: 'aboutUs.contactUs.title' })}
        description={intl.formatMessage({ id: 'aboutUs.contactUs.description' })}
        buttonText={intl.formatMessage({ id: 'aboutUs.contactUs.buttonText' })}
      />
    </Layout>
  )
}

export default injectIntl(AboutUs)
